import { useEffect, useState } from 'react';
import {
  closeWalletConnectModal,
  connectWallet,
  controllers,
  openWalletConnectModal,
  useAddress,
  walletState,
} from '../../state/wallet-state';
import { Button } from '../components/base';
import { Modal } from '../components/modal';
import { WalletController, WalletType } from 'cosmes/wallet';
import { capitalize } from 'lodash';
import { copyToClipboard } from '../../helpers/utils';
import { classicNetwork } from '../../consts/networks';
import { FaCopy, FaMobileScreen, FaPuzzlePiece } from 'react-icons/fa6';
import { t } from 'i18next';

const CurrentWallet = () => {
  const wallet = walletState.use.wallet();
  const controller = walletState.use.currentController();
  return wallet ? (
    <div className="flex w-full flex-col items-start gap-4">
      <div className="flex w-full min-w-0 flex-row items-center justify-between">
        <span className="min-w-0 max-w-[200px] overflow-hidden text-ellipsis text-nowrap text-xl">
          {wallet.address.slice(0, -4)}
        </span>
        <span className="mr-auto text-xl">{wallet.address.slice(-4)}</span>
        <Button icon className="ml-2" onClick={() => copyToClipboard(wallet.address)}>
          <FaCopy className="" />
        </Button>
      </div>
      <div className="flex flex-row justify-between">
        <p>
          via {capitalize(wallet.id)} {wallet.type === WalletType.WALLETCONNECT ? 'Mobile' : 'Extension'}
        </p>
      </div>
      <Button
        onClick={() => {
          controller?.disconnect([classicNetwork.id]);
        }}>
        Disconnect
      </Button>
    </div>
  ) : null;
};

const ControllerSection = ({ controller }: { controller: WalletController }) => {
  const [extensionInstalled, setExtensionInstalled] = useState(false);
  useEffect(() => {
    controller.isInstalled('extension').then(setExtensionInstalled);
  }, [controller]);
  const [mobileInstalled, setMobileInstalled] = useState(false);
  useEffect(() => {
    controller.isInstalled('walletconnect').then(setMobileInstalled);
  }, [controller]);
  return (
    <>
      {extensionInstalled && (
        <Button
          className="flex w-28 flex-col items-center justify-evenly gap-2 rounded-xl py-4"
          onClick={() => connectWallet(controller.id, 'extension')}>
          <FaPuzzlePiece className="size-8" />
          <p className="font-bold">{capitalize(controller.id)}</p>
          <p className="text-xs">Extension</p>
        </Button>
      )}
      {mobileInstalled && (
        <Button
          className="flex w-28 flex-col items-center justify-evenly gap-2 rounded-xl py-4"
          onClick={() => connectWallet(controller.id, 'walletconnect')}>
          <FaMobileScreen className="size-8" />
          <p className="font-bold">{capitalize(controller.id)}</p>
          <p className="text-xs">Mobile App</p>
        </Button>
      )}
    </>
  );
};

export const WalletButton = () => {
  const address = useAddress();
  const open = walletState.use.connectModalIsOpen();
  return (
    <>
      <Button className="connect-wallet" onClick={openWalletConnectModal}>
        {address ? t('head.connected') : t('head.connect')}
      </Button>
      <Modal open={open} onClose={() => closeWalletConnectModal()} title="Wallet">
        {address ? (
          <CurrentWallet />
        ) : (
          <>
            <h2>Connect your wallet:</h2>
            <div className="flex flex-wrap gap-6">
              {Object.values(controllers).map((controller) => (
                <ControllerSection key={controller.id} controller={controller} />
              ))}
            </div>
          </>
        )}
      </Modal>
    </>
  );
};
