import { PropsWithChildren } from 'react';
import { ErrorBoundary } from './errors';
import { cn } from '../../helpers/utils';
import { Link, useLocation } from 'react-router-dom';
import { WalletButton } from './wallet';
import { t } from 'i18next';

export const Layout = ({ children }: PropsWithChildren) => {
  const location = useLocation();
  return (
    <>
      <header className="grid h-14 w-full grid-cols-2 place-items-center sm:grid-cols-[1fr_2fr_1fr]">
        <Link
          to="/"
          id="logo"
          className="flex content-center items-center px-4 text-2xl font-bold transition-all">
          <img src="/assets/logo-desktop.svg" className="max-w-1/3 hidden lg:flex" />
          <img src="/assets/logo-mobile.svg" className="flex lg:hidden" />
        </Link>
        <nav className="font-advent grid grid-cols-4 place-items-stretch text-xl font-bold uppercase">
          <Link
            to="/dashboard"
            className={cn(
              'border-blue/60 flex h-14 items-center justify-center border-b-0 border-solid px-4 text-center transition-all hover:border-b-4',
              location.pathname.startsWith('/dashboard') && 'border-blue border-b-4',
            )}>
            {t('head.dashboard')}
          </Link>
          <Link
            to="/mint"
            className={cn(
              'border-blue/60 flex h-14 items-center justify-center border-b-0 border-solid px-4 text-center transition-all hover:border-b-4',
              location.pathname.startsWith('/mint') && 'border-blue border-b-4',
            )}>
            {t('head.mint')}
          </Link>
          <Link
            to="/auction"
            className={cn(
              'border-blue/60 flex h-14 items-center justify-center border-b-0 border-solid px-4 text-center transition-all hover:border-b-4',
              location.pathname.startsWith('/auction') && 'border-blue border-b-4',
            )}>
            {t('head.auction')}
          </Link>
          <Link
            to="/farm"
            className={cn(
              'border-blue/60 flex h-14 items-center justify-center border-b-0 border-solid px-4 text-center transition-all hover:border-b-4',
              location.pathname.startsWith('/farm') && 'border-blue border-b-4',
            )}>
            {t('head.farm')}
          </Link>
        </nav>
        <WalletButton />
      </header>
      <main className="grid w-dvw max-w-[1240px] grid-cols-1 gap-4 p-4 sm:grid-cols-2 md:grid-cols-4 md:px-8 lg:grid-cols-6">
        <ErrorBoundary>{children}</ErrorBoundary>
      </main>
    </>
  );
};
