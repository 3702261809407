import { Button as Btn, ButtonProps as BtnProps } from '@headlessui/react';
import { cn } from '../../helpers/utils';
import { ComponentProps, Dispatch, ReactNode, SetStateAction } from 'react';

export type ButtonProps = ComponentProps<typeof Button>;

export const Button = ({
  className,
  variant,
  icon,
  big,
  ...props
}: BtnProps & {
  big?: boolean;
  variant?: 'default' | 'ghost';
  icon?: boolean;
}) => (
  <Btn
    {...props}
    className={cn(
      'center flex animate-fade justify-center rounded-sm px-4 py-1 text-sm font-semibold uppercase transition-all',
      'bg-blue text-dark hover:bg-blue/80 focus:bg-blue/70',
      'outline-blue outline-1 outline-offset-0 duration-200 hover:outline-offset-8 hover:outline-transparent',
      variant === 'ghost' && 'text-blue hover:bg-blue/10 focus:bg-blue/15 bg-transparent',
      icon && '!p-[5px]',
      big && 'w-full p-4 text-lg font-bold',
      className,
    )}
  />
);

export const Toggle = ({
  active,
  onClick,
  onChange,
  ...props
}: ComponentProps<typeof Button> & {
  active?: boolean;
  onChange?: Dispatch<SetStateAction<boolean>>;
}) => (
  <Button
    {...props}
    onClick={(e) => {
      onChange?.(!active);
      onClick?.(e);
    }}
  />
);

export type CardProps = ComponentProps<typeof Card>;

export const Card = ({ className, dark, ...props }: ComponentProps<'section'> & { dark?: boolean }) => (
  <section
    {...props}
    className={cn(
      dark ? 'bg-dark' : 'card-bg',
      'border-blue/50 flex animate-fade flex-col items-start justify-start overflow-hidden border border-solid backdrop-blur-2xl transition-all elevation-10',
      className,
    )}
  />
);

export const Heading = ({ children, aside }: ComponentProps<'div'> & { aside?: ReactNode }) => (
  <div className="flex items-center justify-between p-2">
    <h2 className="pr-2 text-xl font-bold uppercase">{children}</h2>
    <div className="border-blue relative bottom-1 grow self-end border-b" />
    {aside}
  </div>
);
