export const NATIVE_TOKEN_DECIMALS = 6;
export const LP_TOKEN_DECIMALS = 6;
export const COMMISSION_RATE = 0.02; // 2%

export const classicNetwork = {
  name: 'classic',
  chainID: 'columbus-5',
  rpc: 'https://terra-classic-rpc.publicnode.com',
  fcd: 'https://terra-classic-fcd.publicnode.com',
  explorer: 'https://finder.terraport.finance/finder/',
  id: 'columbus-5',
  gasPrices: { uusd: 0.15 },
  fee: { gasPrice: 0.15, amount: 100000 }, // 0.1 USTC
  factoryContract: 'terra1m8zz7q49x8phrfwc0rxep77l2u6hf7tm2arv2rmzk5c9lg7p6ncqu3y4zg',
  v3factoryContract: 'terra1hjn2n2nyl3rgdrfxw53hfrhua64y6q0hssa5gp6lelsuxx8etsssz2d76k',
  routerContract: 'terra1g3dcej4he9x8dmj8wlmqsta75u282cnntu67upfel0px7huyp7es5nfa5j',
  vestingContract: 'terra19v3vkpxsxeach4tpdklxaxc9wuwx65jqfs6jzm5cu5yz457hhgmsp4a48n',
  tokenContract: 'terra1ex0hjv3wurhj4wgup4jzlzaqj4av6xqd8le4etml7rg9rs207y4s8cdvrp',
  stakingContract: 'terra134ummlrj2rnv8h8rjhs6a54fng0xlg8wk7a2gwu6vj42pznkf6xs95966d',
  governanceContract: 'terra1m67k3ak5d05k7tzukstkujxptw0tnwt8cmf5kcxm6vwd403l4teshm07ls',
  burnContract: 'terra1rd8f2mhdhgtsfk5afcpfj78wzkljc4lns6t2rhwftvtwzdz95cuqmt7rmm',
  allowedPairContracts: [],
};

export const terraportNetworks = {
  classic: classicNetwork,
};
