import { ComponentProps, ReactNode } from 'react';
import { Card, Heading } from './base';
import { Dialog, DialogBackdrop, DialogPanel, DialogProps } from '@headlessui/react';
import { cn } from '../../helpers/utils';

export const Modal = ({
  open,
  onClose,
  className,
  children,
  title,
  aside,
  ...props
}: ComponentProps<typeof Card> &
  Pick<DialogProps, 'open' | 'onClose'> & { title?: string; aside?: ReactNode }) => {
  return (
    <Dialog open={open} onClose={onClose} className="z-10 flex items-center justify-center">
      <DialogBackdrop className="animate-fade-in fixed inset-0 bg-black/30 backdrop-blur-xl" />
      <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
        <DialogPanel className="transform transition-all">
          <Card
            {...props}
            dark
            className={cn(
              'relative flex min-h-[200px] min-w-[300px] flex-col items-stretch justify-start p-4',
              className,
            )}>
            <Heading aside={aside}>{title ?? ''}</Heading>
            <div className="flex flex-col content-start items-start gap-2 p-4">{children}</div>
          </Card>
        </DialogPanel>
      </div>
    </Dialog>
  );
};

/*

  return (
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-dvh items-center justify-center text-center">
          </div>
        </div>
      </Dialog>
    );
  };
  */
